.topbarActions{
    cursor: pointer;
    text-decoration: none!important;
    /* width: 100vw!important; */
}
.topBar{
    width: 100%;
}
@media screen and (min-width: 300px) and (max-width: 767px) {
.topbarContent{
    font-size: 12px;
}
}