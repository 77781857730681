.slectTab {
  background-color: rgb(67, 179, 227);
}
.revenueAmount{
  font-weight: 600;
}
.batchRevenue {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: auto;
    border-radius: 10px;
    margin: 10px 0;
    padding: 10px;
    color: black;
    background-color: white;
}

.arrow-wrapper{
  width: 5%;
}

.batchRevenue-wrapper{
  width: 25%;
}

.searchWrapper{
  width: 70%;
}