/* * {
  background-color: rgba(250, 250, 250, 100%);
} */

.dasboard1 {
  background-color: rgba(236, 245, 244, 0.8);
}

.dbContainer {
  margin-left: -50px;
}

.db-inputs {
  width: 130px;
  font-size: 14px;
  border: none !important;
  background-color: rgba(250, 250, 250, 100%);
}

.totalCount-wrapper {
  display: flex;
  justify-content: space-between;
}

.totalCount {
  margin: 10px;
  width: 16vw;
  height: 145px;
  border-radius: 10px;
  border: none !important;
  font-size: 16px;
  font-weight: 400;
  color: black;
  background-color: rgba(189, 210, 207, 0.356);
  /* box-shadow: 1px 1px 5px #888888; */
}

.totalCount1 {
  margin: 10px 2px;
  width: 25vw;
  height: 145px;
  border-radius: 10px;
  border: none !important;
  font-size: 16px;
  font-weight: 400;
  color: black;
  background-color: white;
  /* box-shadow: 1px 1px 5px #888888; */
}

.graph {
  background-color: white;
}

.amount {
  font-size: 30px;
  font-weight: 600;
}
.table {
  margin-left: 0px;
}

.totalCount-wrapper {
  display: flex;
  /* flex-direction: column; */
  justify-content: space-evenly;
}

@media screen and (min-width: 300px) and (max-width: 767px) {
  .totalCount-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
  .dasboard1 {
    width: 106%;
    margin-left: -0.7rem;
  }

  .totalCount {
    margin: 10px;
    width: 100%;
    height: 145px;
    border-radius: 10px;
    border: none !important;
    font-size: 16px;
    font-weight: 400;
    color: black;
    /* background-color: white; */
    /* box-shadow: 1px 1px 5px #888888; */
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .totalCount {
    margin: 10px;
    width: 100%;
    height: 145px;
    border-radius: 10px;
    border: none !important;
    font-size: 16px;
    font-weight: 400;
    color: black;
    /* background-color: white; */
    /* box-shadow: 1px 1px 5px #888888; */
  }

  .totalCount-wrapper {
    display: flex;
    /* flex-direction: column; */
    justify-content: space-evenly;
    align-items: center;
  }
  .create-ticket-model {
    margin-left: 15%;
  }
}
