.slectTab {
  background-color: rgb(67, 179, 227);
}
.learnLiveText {
  font-weight: bold;
}
.left-half {
  height: 50vh;
}

.leftImg-wrapper {
  position: absolute;
  width: 50%;
  height: 50%;
}

.zoomLinkImg {
  position: relative;
  top: -20px;
  left: 0;
  width: 50%;
}
@media screen and (min-width: 300px) and (max-width: 768px) {

.satLink {
  /* width: 200px !important; */
  overflow-x: auto;
}
.learnLiveText {
  font-weight: bold;
  font-size: 14px;
}
}
