/* Button background color for Upload your assignment: #0C415F
Font Size: 16px
Color: #ffffff */
.lms-heading{
    text-decoration: underline;
    font-weight: bold;
}
.lms-btn{
    font-size: 16px;
    padding:10px 10px;
     background-color: #0C415F;
     color: #ffffff;
     width: 230px;

}
.arrowImg{
    margin-top: 12px;
}
.lmsImg-wrapper{
    height: 40px;
    border-left: 1px solid rgba(251, 251, 251, 0.4);
}
@media screen and (min-width: 1000px) and (max-width: 1024px) {
    .recording-left{
    width: 100%;
    }
    .recording-right{
    width: 100%;
    }
.recording-content{
display: flex;
flex-direction: column;
}
}