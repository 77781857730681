.stu-main-wrapper {
  background-color: rgba(250, 250, 250, 100%) !important;
  border-radius: 10px!important;
}
.stu-main-wrapper:focus{
    border: none;
}
.stuSubmitBtn{
    background-color: #105877d5;
    color: white;
    width: 250px;
}
.profileImg{
  width:30px;
  height: 30px;
  border-radius: 50%;
}
.profileWrapper{
  border-radius: 5px;
}
.profileInput{
  border: none!important;
}
.popUp-logout-btn{
  background-color: #105877d5;
    color: white;
}

@media screen and (min-width: 300px) and (max-width: 768px) {
  .stu-heading {
    font-size: 18px;
  }
}
