.voucher{
color: red;
font-size: 24px;
}
.horizontal_bar1{
    width: 30px;
}
.horizontal_bar2{
    width: 400px;
}
.poweredByText{
    font-size: 12px;
}
.horizontal_bar3{
    width: 100px;
    height: 22px;
    background-color:  rgb(207, 246, 245);
}
.horizontal_bar4{
    width: 100%;
    height: 15px;
    background-image:linear-gradient(to right, rgb(0, 255, 251), rgb(0, 187, 255))
}
.horizontal_bar1,.horizontal_bar2{        
    height: 22px;
    background-image:linear-gradient(to right, rgb(0, 255, 251), rgb(0, 187, 255))
}
p{
    font-size: 15px;
}