/* @import url("./constant.css");  */

* {
  /* font-family: "Roboto", sans-serif; */
  font-family: "Inclusive Sans", sans-serif;
}

.isBlur {
  filter: blur(4px);
}

.tabIcon {
  width: 1.35rem;
  /* margin: 0.7rem 0; */
  /* height: 1rem; */
}

.ActiveTabIcon {
  width: 2.4rem;
  margin-left: -0.01rem;
  padding: 0px 0;
}

.table {
  font-size: 12px;
  /* width: 100%!important; */
}

.adminDashboard-wrapper {
  z-index: 10000 !important;
  width: 77vw;
  /* background-color: rgb(237, 237, 232); */
  background-color: white;
  margin-left: -5rem;
}

.main-wrapper {
  margin-left: 7rem;
  width: 90.1%;
  background-color: rgba(236, 245, 244, 0.8);
}
.menuBtn{
  background-color: rgba(236, 245, 244, 0.8);
}

.stuData {
  padding: 2rem;
  border-radius: 1rem;
  background-color: white;
  box-shadow: 1px 2px 8px;
}

.internalContainer {
  width: 107%;
  margin-left: -2.5rem;
}

.admin-sidebar-col {
  /* background-color: var(--navColor); */
  /* background-color: rgb(222, 246, 254)!important; */
  color: #f2fcff;
  width: 230px;
  padding-top: 100px;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
}

.sidebarItems {
  /* background-color: rgb(222, 246, 254) !important; */
  /* font-size: 16px; */
}

.adminMenuIcon {
  display: none !important;
}

.tabLogo {
  width: 24px;
}

.main-adminDashboard-wrapper {
  width: 108%;
}

.md-column {
  width: 100%;
  /* margin-left: 40px; */
}

.adminSidebar-item-wrapper {
  cursor: pointer;
  background-color: rgb(222, 246, 254) !important;
}

.admin-sidebar-item {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
  text-align: left;
  color: var(--white);
  padding-top: 5px;
  background-color: rgb(222, 246, 254) !important;
}

Link {
  text-decoration: none;
}

.admin-footer-terms {
  font-size: 14px;
  position: absolute;
  bottom: 10px;
  left: 20px;
}

.adminDashboardImg {
  width: 80px;
  display: flex;
  justify-content: center;
  margin-top: -80px;
  margin-bottom: 20px;
}

.admin-wrapper {
  margin-top: 20px;
}

.adminProfile-wrapper {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid gray;
  overflow: hidden;
  margin-left: auto;
  margin-top: 20px;
}

.adminProfile-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.adminDashboard-profilePicture-wrapper {
  width: 50px;
  height: 50px;
  position: relative;
  border: 1px solid gray;
  border-radius: 50%;
}
.adminPluseImg {
  position: relative;
  top: -25px;
  left: 80%;
  width: 25px;
  height: 25px;
  border-radius: 50% !important;
  background-color: var(--white);
  cursor: pointer;
}
/* adminEducator css */
.admin-status-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.status-box {
  width: 150px;
  height: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: var(--white);
  border-radius: 8px;
  font-size: 16px;
  font-weight: 400;
}

.statusVerified {
  background-color: green;
}

.statusRejected {
  background-color: red;
}

.statusPending {
  background-color: yellow;
}

.adminStatusCount {
  font-size: 20px;
  font-weight: 600;
}

.filters {
  padding: 10px 15px;
  border-radius: 50px;
  background-color: aqua;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.adminPanelUser {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.tabText {
  color: #2dc6c3;
}


.refer-container{
  border-radius:1rem;
  box-shadow: 0px 0px 8px 1px;
}
.refer-container1{
  border-radius:0.5rem;
  /* box-shadow: 0px 0px 4px 1px; */
}

.table {
  width: 78vw;
}

/* educator details  */
.EducatorProfile {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.Introvideo {
  color: aqua;
}
.languageBtn {
  padding: 5px 10px;
  background-color: gray;
  border-radius: 20px;
  margin: 2px;
  font-weight: 500;
}
.educator-accordion-item {
  width: 100% !important;
  height: 100% !important;
  border-radius: 20px !important;
  margin: 10px 0 !important;
}
.courseParaMeter {
  font-weight: 500;
}
.batchInfo {
  border: 2px solid black;
  border-radius: 5px;
  padding: 5px;
}

.adminBatches {
  padding: 5px 10px;
  background-color: rgb(145, 194, 224);
  border-radius: 20px;
  margin: 0 5px;
}
th {
  font-size: 14px;
  font-weight: 400;
}
th,
td {
  text-align: center;
}

.dropdown-menu {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.dropdown-item {
  color: #000;
  background-color: #fff;
}

div::-webkit-scrollbar {
  display: none;
}
.topBar {
  height: 60px;
  /* width: 81.5vw; */
  margin-top: 0;
  padding-top: 20px;
  background-color: rgba(236, 245, 244, 0.8);
}

.snackbar {
  /* position: sticky!important; */
  top: 0 !important;
  left: 40% !important;
  /* transform: translate(30%,50%); */
}

.dbPointer {
  cursor: pointer !important;
}

.downArrow {
  rotate: -90deg;
}

@media screen and (min-width: 300px) and (max-width: 767px) {

  .adminDashboard-wrapper {
    width: 100%;
    margin: 0px;
  }

  .hacker-wrapper{
    /* border: 1px solid red; */
    width: 99vw;
    margin-left: -0.7rem;
    margin-top: -3rem;
    background-color: white;
  }

  .table {
    width: 100%;
  }

  .internalContainer {
    width: 100%;
    margin-left: 0rem;
  }

  .main-wrapper {
    margin-left: 0rem;
    width: 100%;
    /* background-color: rgba(236, 245, 244, 0.8); */
    background-color: white;
  }

  .main-adminDashboard-wrapper {
    width: 89vw;
    margin-left: -60px;
  }
  .adminMenuIcon {
    display: block !important;
    background-color: var(--navColor);
    color: var(--white) !important;
    height: 65px;
    position: relative;
    top: 0px;
    border: none;
  }
  .status-box {
    width: 100px;
    height: 50px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: var(--white);
    border-radius: 8px;
    font-size: 12px;
    padding: 5px 0;
    font-weight: 400;
  }
  .backbtn {
    margin-top: 40px;
  }
  .backbtn2 {
    margin-top: 60px;
  }
  .backbtn3 {
    margin-top: 60px;
  }

  /* old  */
}

@media screen and (width: 540px) {
  .main-adminDashboard-wrapper {
    width: 85vw;
    margin-left: -80px;
  }
  .table {
    width: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .main-adminDashboard-wrapper {
    width: 65vw;
    margin-left: 120px;
  }

  .internalContainer {
    width: 107%;
    margin-left: 1rem;
    background-color: white;
  }

  .main-wrapper {
    margin-left: -1rem;
    width: 100%;
    /* background-color: rgba(236, 245, 244, 0.8); */
    background-color:white;
  }

  .adminDashboard-wrapper {
    z-index: 10000 !important;
    width: 62vw;
    margin-left: 30%;
  }
  /* old  */
  .student-sidebar-col {
    background-color: var(--navColor);
    color: #f2fcff;
    width: 27vw;
    padding-top: 100px;
    height: 100vh;
    position: relative;
  }
  .main-stuDashboard-wrapper {
    margin-left: 30px;
    width: 65vw;
  }
  .student-footer-terms {
    position: absolute;
    bottom: 5px;
    left: 5px;
    font-size: 10px;
  }
  .tabLogo {
    width: 17px;
  }
  .announcement-card {
    width: 100%;
  }
  .announcement-inner-wrapper {
    width: 95%;
    margin-left: 5px;
  }
  .AssignentSub {
    margin-top: 10px;
  }
  .grade {
    margin-top: 10px;
  }
  .ann-para {
    width: 87%;
    /* font-weight: 300; */
    font-size: 11px;
    line-height: 16px;
  }
}

@media screen and (min-width: 910px) and (max-width: 1024px) {
  .adminDashboard-wrapper {
    z-index: 10000 !important;
    width: 67vw;
    margin-left: 22%;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1300px) {
  .student-sidebar-col {
    background-color: var(--navColor);
    color: #f2fcff;
    width: 24vw;
    padding-top: 100px;
    height: 100vh;
    position: relative;
  }

  .attendance,
  .grade,
  .AssignentSub,
  .testSubmited {
    width: 160px;
    height: 70px;
    border-radius: 5px;
    padding: 0 4px;
    margin: 0px auto;
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .announcement-inner-wrapper {
    width: 95%;
    margin-left: 5px;
  }
}

/* ////////////////// */

.tab-wrapper {
  overflow: scroll;
}

.sidebar {
  color: black;
  width: 230px;
  box-sizing: border-box;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  /* border: 1px solid #999; */
}

.mainTab {
  font-size: 14px;
}

.mainTab:hover {
  cursor: pointer;
  border-radius: 32px;
  background-color: #f0f0f0;
}

.sidebar li {
  padding: 3px 10px 3px 10px;
  cursor: pointer;
}

.sidebar ul li ul li:hover {
  color: rgb(65, 156, 248);
}

.sidebar ul li ul li {
  font-size: 14px;
}

.sidebar ul ul {
  margin-left: 20px;
  list-style: none;
}

.sidebar i {
  margin-left: 5px;
}
