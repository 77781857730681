.dropdown-menu {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.dropdown-item {
  color: white;
  background-color: #000;
}

.supportTable {
  width: 140px !important;
  font-size: 12px !important;
  /* color: red; */
  /* border: 1px solid blue; */
}
.srNo {
  width: 50px !important;
}
.custom-bordered {
  border-color: rgba(0, 0, 0, 0.05) !important;
}
.table {
  font-size: 12px;
  width: 100% !important;
  scrollbar-width: 5px!important;
}
