
.tabs {
  cursor: pointer;
  color: white;
}
.slectTab{
  background-color: rgb(67, 179, 227);
}
.courseSearch{
  border: 1px solid white!important;

}