*{
    margin: 0;
}

/* input[type=file] {
    color: transparent !important;
} */

/* input[type=file]::after {
  content: "Select Blog Image";
  color: rgb(164, 164, 164);
} */