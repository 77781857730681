.recordImg-wrapper{
    border: 1px dashed black;
    padding: 15px;
}
.accessRecordingBtn{
    width: 250px;
    background-color: #C6E1EF;
    color: black;
    padding: 10px;
    border-radius: 10px;
}
.rightArow{
    color: #0C415F;
}
.recText{
    font-weight: bold;
    font-size: 20px;
}
@media screen and (min-width: 300px) and (max-width: 768px) {
.AssImg{
    width: 100%;
}
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
.AssImg{
    width: 100%;
}
}