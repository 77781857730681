.img-wrapper {
  /* width: 40%; */
  /* height: 300px; */
}
.inner-container {
  padding-top: 20vh;
}
Input {
  /* background: #F6F6F6!important; */
  border: 0px solid #dbdbdb !important;
  border-radius: 5px !important;
}
input {
  /* background: #F6F6F6!important; */
  border: 1px solid #dbdbdb !important;
  border-radius: 5px !important;
}
.sub-btn {
  width: 100%;
  /* height: 33px; */
  left: 422px;
  top: 646px;
  background: #105877;
  background: #4fe2dd;
  border-radius: 8px;
}

.main-wrapper {
  height: 100vh;
}

.welcome-text {
  font-size: 20px;
}


@media screen and (min-width: 300px) and (max-width: 767px) {
  .inner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
